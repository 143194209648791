<template>
  <div class="h_header">
    <TopInfo v-show="isPC" class="slide-in" />

    <el-row v-show="!isPC" class="main-header ">
      <!-- logo -->
      <el-col :span="16" align="left">
        <!-- <nuxt-link to="/" style="width: 20vw;height: 100vh;"> -->
        <img v-show="websiteInfo" alt="" :src="websiteInfo[0]?.image" class="logo" style="width: 300px; height: auto;">
        <!-- </nuxt-link> -->
      </el-col>
      <el-col :span="5" align="right">
        <!-- <nuxt-link to="/"> -->
        <i class="el-icon-menu" style="color: #009173;font-size: 10vw;" @click="toggleMenu" />
        <!-- </nuxt-link> -->
      </el-col>

    </el-row>
    <!-- 导航栏菜单 pc-->
    <el-row v-show="isPC" class="main-nav">
      <li v-for="(item, index) in filterNav(navList)" :key="index" class="mainNav" style="flex: 1">
        <!--        <nuxt-link-->
        <!--          v-if="item.mainColumn.id === 11"-->
        <!--          to="/contact"-->
        <!--        >-->
        <!--          <span-->
        <!--            style="-->
        <!--              color: #fff;-->
        <!--              font-size: 1.4vw;-->
        <!--              text-align: center;-->
        <!--              height: 4.5vw;-->
        <!--              display: flex;-->
        <!--              flex-direction: column;-->
        <!--              justify-content: center;-->
        <!--            "-->
        <!--          >-->
        <!--            {{ item.mainColumn.name }}-->
        <!--          </span>-->
        <!--        </nuxt-link>-->
        <nuxt-link v-show="item.mainColumn.code !== 'zczx' && item.mainColumn.code !== 'gxfb'"
          :to="localePath(item.mainColumn.code == 'wzsy' || item.subColumn == undefined ? '/' : { name: 'submenu-id', params: { id: item.subColumn[0].code }}) "
        >
          <span
            style="
              color: #fff;
              font-size: 1.4vw;
              text-align: center;
              height: 4.5vw;
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <!--                    {{ $t(item.mainColumn.code) }}-->
            {{ item.mainColumn.name }}
          </span>
        </nuxt-link>
        <div v-show="item.mainColumn.code === 'zczx'|| item.mainColumn.code === 'gxfb'" @click="navigateToMainMenu(item)"
        >
          <span
            style="
             cursor: pointer;
              color: #fff;
              font-size: 1.4vw;
              text-align: center;
              height: 4.5vw;
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <!--                    {{ $t(item.mainColumn.code) }}-->
            {{ item.mainColumn.name }}
          </span>
        </div>

        <ul v-show="item.subColumn != null" class="subnav">
          <li v-for="(childItem, index) in filterSubNav(item.subColumn)" :key="index">
            <!-- :target="childItem.target" -->
            <span
              v-show="(item.mainColumn?.code === 'zczx' && childItem.code!=='xmsbzx')|| item.mainColumn?.code === 'gxfb'"
              class="nav-item-span"
              @click="navigateToSubMenu(childItem)"
            >
              {{ childItem.name }}
            </span>
            <nuxt-link v-show="childItem.code==='xmsbzx'" :to="localePath({ name: 'submenu-id', params: { id: childItem.code,other:childItem } })">
              {{ childItem.name }}
              <!--              {{ $t(childItem.code) }}-->
            </nuxt-link>
            <nuxt-link v-show="item.mainColumn?.code !== 'zczx' && item.mainColumn?.code !== 'gxfb'" :to="localePath({ name: 'submenu-id', params: { id: childItem.code,other:childItem } })">
              {{ childItem.name }}
              <!--              {{ $t(childItem.code) }}-->
            </nuxt-link>
          </li>
        </ul>
      </li>
    </el-row>

    <div v-show="!isPC" class="mobile-menu" :class="{ open: menuOpen }">
      <el-row v-show="!isPC" class="main-header ">
        <!-- logo -->
        <el-col :span="18" align="left" style="font-size: 5vw;line-height: 15vw;">
          {{ websiteInfo[0]?.name }}
        </el-col>
        <!-- <el-col v-if="currentLocale==='enObj'" :span="18" align="left" style="font-size: 5vw;line-height: 15vw;">
          {{ websiteInfo[0]?.nameEn }}
        </el-col> -->
        <el-col :span="5" align="right">
          <nuxt-link to="/">
            <i class="el-icon-close" style="color: #7a7a7a;font-size: 6vw;font-weight: bold;" @click="toggleMenu" />
          </nuxt-link>
        </el-col>

      </el-row>

      <ul>
        <li
          v-for="(item, index) in filterNav(navList)"
          :key="index"
          style="border-bottom: 1px solid #444; position: relative;"
        >
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <nuxt-link v-show="item.mainColumn.code !== 'zczx' && item.mainColumn.code !== 'gxfb'"
              class="mobile-menu-main"
              :to="localePath(item?.mainColumn?.code == 'wzsy' || item?.subColumn == undefined ? '/' : { name: 'submenu-id', params: { id: item.subColumn[0].code,other:item.subColumn[0] }}) "
            >
              {{ item.mainColumn?.name }}
              <!--              {{ // $t(item.mainColumn.code) }}-->
            </nuxt-link>
            <div @click="navigateToMainMenu(item)" class="mobile-menu-main" v-show="item.mainColumn.code === 'zczx'|| item.mainColumn.code === 'gxfb'"
            >
              <span>
                <!--                    {{ $t(item.mainColumn.code) }}-->
                {{ item.mainColumn.name }}
              </span>
            </div>

            <i
              v-show="item?.subColumn"
              :class="activeSubMenuIndexes.includes(index) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              style="color: #fff; font-size: 5vw; margin-left: auto;"
              class="menu-btn"
              @click="toggleSubMenu(index)"
            />
          </div>

          <ul v-show="activeSubMenuIndexes.includes(index)">
            <li
              v-for="(childItem, childIndex) in filterSubNav(item?.subColumn)"
              :key="childIndex"
              style=" border-top: 1px solid #444;"
            >
              <span
                v-show="(item.mainColumn?.code === 'zczx' && childItem.code!=='xmsbzx')|| item.mainColumn?.code === 'gxfb'"
                class="mobile-menu-sub"
                @click="navigateToSubMenu(childItem)"
              >
                {{ childItem.name }}
              </span>
              <nuxt-link class="mobile-menu-sub" v-show="childItem.code==='xmsbzx'" :to="localePath({ name: 'submenu-id', params: { id: childItem.code,other:childItem } })">
                {{ childItem.name }}
                <!--              {{ $t(childItem.code) }}-->
              </nuxt-link>
              <nuxt-link
                v-show="item.mainColumn?.code !== 'zczx' && item.mainColumn?.code !== 'gxfb'"
                class="mobile-menu-sub"
                :to="localePath({ name: 'submenu-id', params: { id: childItem.code,other:childItem } })"
              >
                <!--                {{ $t(childItem.code) }}-->
                {{ childItem.name }}
              </nuxt-link>
            </li>
          </ul>
        </li>
      </ul>

      <TopInfoMobile class="slide-in" style="margin-top: 15vw;" />
    </div>
    <div v-show="!isPC && menuOpen" class="overlay" @click="toggleMenu" />

  </div>
</template>

<script>
// import bq from 'bq-static'
import TopInfo from './TopInfo.vue'
import TopInfoMobile from './TopInfo-mobile.vue'
import {
  getStore
} from '../../utils/storage'
export default {
  components: {
    TopInfo,
    TopInfoMobile
  },
  props: {
    active: {
      type: String,
      default: ''
    },
    hideTop: {
      type: Boolean,
      default: false
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentlocale: this.$store.state.currentlocale,
      activeSubMenuIndexes: [], // 存储展开的子菜单索引
      menuOpen: false,
      isPC: false,
      isNow: '',
      search: '',
      menuShow: false,
      websiteInfo: this.$store.state.websiteInfo,
      navList: this.$store.state.navList,
      height: 0,
      userInfo: this.$store.state.userInfo
    }
  },

  beforeDestroy() {

    window.removeEventListener('resize', this.checkDevice)
  },
  mounted() {
    this.getLocale()
    this.checkDevice()

    window.addEventListener('resize', this.checkDevice)
    // bq()
    this.search = this.searchText
    this.isNow = this.$route.path
    this.userInfo = this.$store.state.userInfo
    console.log('userInfo', this.userInfo)
    this.navList = this.$store.state.navList
    console.log('this.$store.state.navList===============', this.$store.state.navList)
  },
  methods: {
    navigateToSubMenu(childItem) {
      if (this.$store.state.tokenInfo) {
        this.$router.push(this.localePath({ name: 'submenu-id', params: { id: childItem.code, other: childItem }}))
      } else {
        this.$message.warning('该栏目需要注册成为陕西省服务贸易协会会员后才能访问。请点击网站右上角完成注册。')
      }
    },
    navigateToMainMenu(item) {
      if (this.$store.state.tokenInfo) {
        this.$router.push(this.localePath(item.mainColumn.code == 'wzsy' || item.subColumn == undefined ? '/' : { name: 'submenu-id', params: { id: item.subColumn[0].code }}))
      } else {
        this.$message.warning('该栏目需要注册成为陕西省服务贸易协会会员后才能访问。请点击网站右上角完成注册。')
      }
    },
    getLocale() {
      this.currentlocale = getStore('currentLocale')
    },
    toggleSubMenu(index) {

      const idx = this.activeSubMenuIndexes.indexOf(index)
      if (idx > -1) {
        this.activeSubMenuIndexes.splice(idx, 1) // 移除索引
      } else {
        this.activeSubMenuIndexes.push(index) // 添加索引
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    // checkDevice() {
    //   this.isPC = window.innerWidth > 768;
    // },

    checkDevice() {

      if (typeof window !== 'undefined') {
        this.isPC = window.innerWidth > 768 // 根据需要调整阈值
      }
    },
    signOut() {
      this.$store.commit('SIGN_OUT')
      this.userInfo = ''
      if (this.$route.path.includes('account')) {
        this.$router.push(this.localePath({
          name: 'login'
        }))
      } else {
        // window.location.reload()
        this.$router.push('/');
      }
    },
    login() {
      this.$store.commit('SET_TEMPORARYURL')
      this.$router.push(this.localePath({
        name: 'login'
      }))
    },
    register() {
      this.$router.push(this.localePath({
        name: 'login',
        query: {
          tab: 2
        }
      }))
    },
    // 处理下拉菜单选项
    handleCommand(command) {
      if (command === 'account') {
        this.$router.push(this.localePath({
          name: 'account'
        }))
      } else if (command === 'logout') {
        this.signOut()
      }
    },
    // 过滤导航栏列表
    filterNav(navlist) {
      let filterList = []
      // isHidden为0（正常显示)且不需要登录筛选
      filterList = navlist.filter(item => (item.mainColumn.isHidden === 0 && item.mainColumn.isLogin === 0) || (item
        .mainColumn.isLogin === 1 && this.userInfo))
      return filterList
    },
    // 过滤子栏目
    filterSubNav(sublist) {
      let filterList = []
      if (sublist) {
        console.log('sublist', sublist)
        // isHidden为0（正常显示)且不需要登录筛选
        filterList = sublist?.filter(item => (item.isHidden === 0 && item.isLogin === 0) || (item.isLogin === 1 && this.userInfo))
        return filterList
      }
    }
    // 跳转参数
    //
    // loadUserInfo() {
    //   this.$store.dispatch('GET_USERINFO').then(() => {
    //     this.userInfo = this.$store.state.userInfo;
    //     console.log("this is true userinfo:",this.userInfo)
    //   });
    // }
  }
}
</script>

<style lang="scss" scoped>
  .nav-item-span{
    cursor: pointer;
      display: inline-block;
      color: #000;
  }
  .nav-item-span:hover {
    color: #fff; /* 鼠标悬停时的字体颜色 */
  }
  .mobile-menu {
    padding: 2vw 3vw;
    color: white;
    position: fixed;
    top: 0;
    left: -70vw;
    width: 70vw;
    height: 100%;
    background: #2c2c2c;
    /* 深色背景 */
    transition: left 0.3s ease;
    /* 平滑过渡效果 */
    z-index: 998;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
    /* 添加阴影 */
    overflow-y: auto;
  }

  .mobile-menu.open {
    left: 0;
  }

  .mobile-menu-main {
    font-size: 4.5vw;
    color: #fff !important;
    /* 更细的边框 */
    width: 100%;
    line-height: 13vw;
    padding: 1vw;
    /* 内边距 */
    transition: background 0.3s;
    /* 背景过渡 */
  }

  .mobile-menu-main:hover {
    background: rgba(255, 255, 255, 0.1);
    /* 悬停效果 */
  }

  .mobile-menu-sub {
    font-size: 4vw;
    /* 稍微小一点的字体 */
    color: #ddd !important;
    /* 更柔和的颜色 */
    width: 100%;
    line-height: 13vw;
    /* 减小行高 */
    padding: 0.5vw;
    /* 内边距 */
    transition: background 0.3s;
    /* 背景过渡 */
  }

  .mobile-menu-sub:hover {
    background: rgba(255, 255, 255, 0.1);
    /* 悬停效果 */
  }

  .overlay {
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* 更深的遮罩效果 */
    z-index: 997;
  }

  .menu-btn {
    width: 7vw;
    height: 7vw;
    background: #009173;
    align-items: center;
    line-height: 7vw;
    text-align: center;
    margin: auto;

  }

  i {
    cursor: pointer;
    /* 指针样式 */
    transition: transform 0.2s;
    /* 动画效果 */
  }

  i:hover {
    transform: scale(1.1);
    /* 悬停时放大 */
  }

  @media (max-width: 768px) {
    .main-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      height: 15vh;

    }
  }

  .h_header {
    background: #fff;

    .el-dropdown-link {
      font-size: 1vw;
      cursor: pointer;
      //color: #409EFF;
    }

    .el-icon-arrow-down {
      font-size: 1vw;
    }
  }

  .main-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .logo {
      padding: 3vw;
      width: 35vw;
      height: auto
    }
  }

  .main-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 4.5vw;
    background: #009173;
  }

  ////new-start
  :deep(.el-input__inner) {
    height: 4.5vw;
    border-radius: 10vw;
    border: 0.2vw solid #009173;
    font-size: 1.5vw;
  }

  :deep(.el-input) {
    width: 70%;
  }

  :deep(.el-input__icon) {
    font-size: 2.5vw;
    color: white;
    width: 7vw;
    height: 3.85vw;
    //padding: 0.18vw;
    border-radius: 10vw;
    background-image: linear-gradient(to bottom, rgb(29, 180, 72), #009173);
    // background: #009173;
    line-height: 3.85vw;
    cursor: pointer;
  }

  :deep(.el-input__prefix) {
    //height: 3vw;
    left: 27.235vw;
    padding: 0.3vw 0;
  }

  .mainNav {
    .subnav {
      display: none;
      position: relative;
      left: 0;
      background: #fff;
      color: #ccc; //width: 6.95vw;
      overflow: hidden;
      border: 1px solid #009176;
      border-top: none;

      li {
        text-align: center;
        line-height: 4vw;

        &:hover {
          background: #009176;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          a {
            color: white !important;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background: #009176;

      .subnav {
        display: block;
        z-index: 55;

        li {
          //background: #fff;
          font-size: 1.2vw;
        }
      }
    }
  }
</style>
