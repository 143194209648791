<template>
  <div style="background: #2c2c2c;color: #fff;">

    <ul>
      <li v-show="!userInfo" style="position: relative;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <i class="el-icon-user-solid" style="color: #009173;font-size: 6vw;font-weight: bold;" />
          <nuxt-link :to="localePath('/login')" class="mobile-menu-main">{{ $t("login") }}</nuxt-link>
        </div>
      </li>
      <li v-show="!userInfo" style="position: relative;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <i class="el-icon-edit-outline" style="color: #009173;font-size: 6vw;font-weight: bold;" />
          <nuxt-link :to="localePath('/register')" class="mobile-menu-main">{{ $t("register") }}</nuxt-link>
        </div>
      </li>
      <li v-show="userInfo" style="position: relative;" @click="showSubM">
        <div style="display: flex; align-items: center;" class="mobile-menu-main">
          <i class="el-icon-s-home" style="color: #009173;font-size: 6vw;font-weight: bold;margin-right:1vw ;" />
          {{ $t("personCenter") }}
        </div>

        <ul v-show="showSub">
          <li class="mobile-menu-sub" style=" border-top: 1px solid #444;" @click="handleCommand('account')">
            {{ $t("personCenter") }}
          </li>
          <li class="mobile-menu-sub" style=" border-top: 1px solid #444;" @click="handleCommand('logout')">
            {{ $t("logout") }}
          </li>
        </ul>
      </li>
      <li style="position: relative;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div class="mobile-menu-main" @click="switchLanguage('enObj')">English</div>
          <div class="mobile-menu-main" @click="switchLanguage('zhObj')">中    文</div>
        </div>
      </li>
    </ul>

    <!-- logo -->
    <!-- <el-row class="main-header ">

      <el-col :span="12" align="center">
        <nuxt-link to="/">
          <img
            v-show="websiteInfo"
            alt=""
            :src="websiteInfo[0]?.url + websiteInfo[0]?.image"
            class="logo"
          >
        </nuxt-link>
      </el-col>
      <el-col :span="12" align="center" />
    </el-row> -->

  </div>
</template>
<script>
import { logout } from '../../api/login'
import { getStore } from '../../utils/storage'

export default {
  data() {
    return {
      showSub: false,
      isPC: false,
      currentLocale: 'zhObj',
      isNow: '',
      search: '',
      menuShow: false,
      websiteInfo: this.$store.state.websiteInfo,
      navList: this.$store.state.navList,
      height: 0,
      userInfo: this.$store.state.userInfo
    }
  },

  mounted() {
    this.search = this.searchText
    this.isNow = this.$route.path
    this.userInfo = this.$store.state.userInfo
    if (getStore('currentLocale')) {

      this.currentLocale = getStore('currentLocale')
      this.loadLanguage(this.currentLocale)
      // this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${this.currentLocale}` }}})
      // this.$store.dispatch('fetchTranslations', this.currentLocale)
      // this.$i18n.setLocale(this.currentLocale)
    } else {
      // this.$store.dispatch('fetchTranslations', 'zhObj')
      this.loadLanguage('zhObj')
      // this.$i18n.setLocale('zhObj')
    }
    // if (getStore('currentLocale')) {
    //   this.currentLocale = getStore('currentLocale')
    //   // this.switchLanguage(getStore('currentLocale'))
    // } else {
    //   this.switchLanguage('zhObj')
    // }
    console.log('userInfo', this.userInfo)
  },
  methods: {
    async switchLanguage(locale) {
      this.$i18n.setLocale(locale) // 更新当前语言环境
      await this.$store.dispatch('switchLocale', locale).then(async(res) => {
        if (process.client) {

          await this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${locale}` }}}).then(res=>{
            this.$router.push(this.localePath('/'))
          })
        }
      })
    },
    async loadLanguage(locale) {
      this.$i18n.setLocale(locale) // 更新当前语言环境
      await this.$store.dispatch('switchLocale', locale).then(async(res) => {
        if (process.client) {

          await this.$store.dispatch('nuxtServerInit', { req: { 'headers': { 'cookie': `i18n_redirected=${locale}` }}})
        }
      })
    },
    checkDevice() {
      if (typeof window !== 'undefined') {
        this.isPC = window.innerWidth > 768 // 根据需要调整阈值
      }
    },
    showSubM() {
      this.showSub = !this.showSub
    },
    showMenu() {
      this.menuShow = true
    },
    hideMenu() {
      this.menuShow = false
    },
    handleSearch() {
      this.$router.push(this.localePath({ name: 'search', query: { search: this.search }}))
    },
    signOut() {
      // 调用退出登录
      logout().then(res => {
        this.$store.commit('SIGN_OUT')
        this.userInfo = ''
        this.$router.push(this.localePath('/'))
        if (this.$route.path.includes('account')) {
          this.$router.push(this.localePath({ name: 'login' }))
        } else {
          // window.location.reload()
          this.$router.push('/');
        }
      })
    },
    login() {
      this.$store.commit('SET_TEMPORARYURL')
      this.$router.push(this.localePath({ name: 'login' }))
    },
    register() {
      this.$router.push(this.localePath({ name: 'login', query: { tab: 2 }}))
    },
    // 处理下拉菜单选项
    handleCommand(command) {
      if (command === 'account') {
        this.$router.push(this.localePath({ name: 'account' }))
      } else if (command === 'logout') {
        this.signOut()
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
  .mobile-menu-main {
    font-size: 4.5vw;
    color: #fff !important;
    /* 更细的边框 */
    width: 100%;
    line-height: 13vw;
    padding: 1vw;
    /* 内边距 */
    transition: background 0.3s;
    /* 背景过渡 */
  }

  .mobile-menu-main:hover {
    background: rgba(255, 255, 255, 0.1);
    /* 悬停效果 */
  }

  .mobile-menu-sub {
    font-size: 4vw;
    /* 稍微小一点的字体 */
    color: #ddd !important;
    /* 更柔和的颜色 */
    width: 100%;
    line-height: 13vw;
    /* 减小行高 */
    padding: 0.5vw;
    /* 内边距 */
    transition: background 0.3s;
    /* 背景过渡 */
  }

  .mobile-menu-sub:hover {
    background: rgba(255, 255, 255, 0.1);
    /* 悬停效果 */
  }
// .tab-text{
//   color: #fff !important;
// }

.welcome{
  padding: 0.5vw;font-size: 3vw;
}
.h_header {
  background: #fff;

  .el-dropdown-link {
    font-size: 1vw;
    cursor: pointer;
    //color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 1vw;
  }

}

.main-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  .logo {
    padding: 3vw;
    width: 60%;
    height: auto
  }
}

.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 4.5vw;
  background: #009173;
}

////new-start
:deep(.el-input__inner) {
  height: 4.5vw;
  border-radius: 10vw;
  border: 0.2vw solid #009173;
  font-size: 1.5vw;
}

:deep(.el-input) {
  width: 70%;
}

:deep(.el-input__icon) {
  font-size: 2.5vw;
  color: white;
  width: 7vw;
  height: 3.85vw;
  //padding: 0.18vw;
  border-radius: 10vw;
  background-image: linear-gradient(to bottom, rgb(29, 115, 180), #009173);
  // background: #009173;
  line-height: 3.85vw;
  cursor: pointer;
}

:deep(.el-input__prefix) {
  //height: 3vw;
  left: 27.235vw;
  padding: 0.3vw 0;
}

.mainNav {
  .subnav {
    display: none;
    position: relative;
    left: 0;
    background: #fff;
    color: #ccc; //width: 6.95vw;
    overflow: hidden;
    //border: 1px solid #009176;
    border-top: none;

    li {
      text-align: center;
      line-height: 4vw;

      &:hover {
        background: #009176;

        a {
          color: white !important;
        }
      }
    }
  }

  &:hover {
    background: #009176;
    text-decoration: none;

    .subnav {
      display: block;
      z-index: 55;

      li {
        //background: #fff;
        font-size: 1.2vw;
      }
    }
  }
}
</style>
