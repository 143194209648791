import http from './method'

// 用户登录
export const userLogin = (params = {}) => {
  return http().post('/user/api/users/login', params)
}
// 发送注册验证码
export const getMobileCode = (params) => {
  return http().post('/user/api/users/send/code', params)
}
// 用户注册
export const register = (params = {}) => {
  return http().post('/api/system/user/add', params)
}
// 修改密码
export const updatePassword = (params = {}) => {
  return http().post('/user/api/users/password', params)
}

export const trylogin = (param) => {
  return http().post(`/api/auth/oauth/token?username=${param.username}&password=${param.password}&captcha=${param.captcha}&key=${param.key}&client_id=javaweb&client_secret=123456&grant_type=password&scope=server`)
}
// 退出登录
export const logout = () => {
  return http().delete('/api/auth/token/logout')
}

export const getrandcode = () => {
  return http().get('/api/system/login/captcha')
}

// 申请会员
export const applymember = (params = {}) => {
  return http().post('/api/member/member/add', params)
}
// 发送短信验证码
export const sendVerityCode = (mobile) => {
  return http().get('/api/system/sms/sendVerityCode?mobile=' + mobile)
}
// 密码重置
export const smsResetPwd = (params = {}) => {
  return http().get('/api/system/sms/smsResetPwd?' + params)
}
